/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .partner-agencies__element { ... }
// .partner-agencies__element--modifier { ... }
// .partner-agencies__sub-element { ... }


.partner-agencies__list {
    display: flex;
    flex-flow: row wrap;
}

.partner-agencies__item {
    padding: 25px 5px;
    padding-left: 30px;
    width: 50%;
}

