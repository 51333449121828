
/*
--------------------
Modules
--------------------
*/

#page-wrapper {
    max-width: 900px;
    margin: 0 auto;
}