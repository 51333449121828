/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .partner-agencies__element { ... }
// .partner-agencies__element--modifier { ... }
// .partner-agencies__sub-element { ... }

.partner-agencies {
    padding: 0 16px;
}

.partner-agencies__list {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
}

.partner-agencies__item {
    list-style-type: none; 
    padding: 25px;
    margin: 0 auto;
}

.phone-link {
    text-decoration: none;
}

a {
    color: #666;
}

a:hover {
    color: rgb(119, 163, 199);
}

.partner-agencies__NSW {
    width: 196px; 
    height: 60px;
    margin: 10px 0;
}

.partner-agencies__ACT {
    width:148px; 
    height: 66px; 
    margin: 6px 0;
}

.partner-agencies__WA {
    width: 138px;
    height: 86px; 
    margin: -4px 0;
}


.partner-agencies__PLSS {
    width: 151.03px;
    height: 35px; 
    margin: 30px 0 13px;
}

.partner-agencies__VIC {
    width: 126px;
    height: 48px; 
    margin: 31px 0 -2px 0;
}

.partner-agencies__NT {
    width: 88px;
    height: 53px; 
    margin: 22px 0 3px 0;
}

.partner-agencies__QLeave {
    width: 200px;
    height: 35px; 
    margin: 32px 0 11px 0;
}

.partner-agencies__TAS {
    width: 151px; 
    height: 36px;
    margin: 32px 0 10px 0;
}