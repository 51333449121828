/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .footer__element { ... }
// .footer__element--modifier { ... }
// .footer__sub-element { ... }

.footer {
    padding: 0 16px;
    width: 900px;
    margin-right: auto;
    margin-left: auto;
    bottom: 0;
    width: 100%;
}

.footer__background {
    background-color: #ECF5F7;
    height: 62px;
    margin-top: 30px;
    // Your styles here
}