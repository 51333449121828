/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .form__element { ... }
// .form__element--modifier { ... }
// .form__sub-element { ... }


form {
    font-size: 0.8rem;
    width: 60%;
}

.sq-form-section {
    margin: 1rem auto 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
}
.sq-form-section-title {
    margin: 0 0 10px -10px;
    width: 104%;
    font-size: 1.1rem;
}
// form date

.form__date-of-birth-label {
    margin-right: 48px;
}
.form__date {
    height: 21px;
    margin: 0px -10px 0px 10px;
}

.dob_day {
    width: 46px;
}

.dob_month {
    width: 57px;
}

.dob_year {
    width: 60px;
}

.form__email {
    margin-left: 87px;
}

.form__phone {
    margin-left: 47px;
    margin-bottom: 20px;
}

input {
    width: 93%;
    margin: 10px 0 10px 19px;
}

label {
    margin-top: 10px;
    margin-left: 20px;
}

select {
    width: 93%;
    margin-left: 20px;
}

.sq-form-submit {
    margin-top:0px;
    width: 20%;
    color: black;
    height: 2rem;
}

legend {
    margin-left: 10px;
}

// thank you form

.form__thank-you-section {
    margin: 100px auto;
    width: 55%;
}