/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .form__element { ... }
// .form__element--modifier { ... }
// .form__sub-element { ... }

form {
    margin: 30px auto;
    border: 3px solid #3f7bad;
    border-top: none;
}
.sq-form-section {
    margin: 1rem auto -1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
}

.sq-form-section-title {
    background-color: #3f7bad;
    color: white;
    margin: 0;
    padding: 5px 0;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 400;
    width: 105%;
}

.sq-form-section:first-of-type .sq-form-section-title {
    font-size: 1.5rem;
}

.sq-form-required-field {
    color: red;
}

// date 

.form__date {
    height: 21px;
}
.dob_day {
    width: 37px;
}

.dob_month {
    width: 48px;
}

.dob_year {
    width: 60px;
}

.sq-form-question-datetime label {
     display: none;
}

input {
    border: 1px solid lightgrey;
    border-radius: 3px;
    width: 100%;
    margin: 5px 0;
    color: darkSlateGrey;
    height: 1.6rem;
    outline: none;
    border-color: lightgrey;
    box-shadow: 0 0 10px lightgrey;
    font-size: 1rem;
    color: black
}

select {
    border: 1px solid lightgrey;
    border-radius: 3px;
    width: 100%;
    margin: 5px 0 10px;
    color: darkSlateGrey;
    height: 1.6rem;
    outline: none;
    border-color: lightgrey;
    box-shadow: 0 0 10px lightgrey;
    font-size: 0.8rem;
}

hr {
    color: lightgrey;
    padding: 0;
}

fieldset {
    border: none;
}

abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.sq-form-submit {
    margin: 30px;
    width: 80%;
    color: black;
    height: 2rem;
    background-color: #F4F4F4;
}

.sq-form-submit:hover {
    background-color: #D3D3D3;
}

legend {
    margin-left: -6px;
}

// Thank you form 

.form__thank-you-section {
    border: 3px solid #3f7bad;
    margin: 100px 20px;
    padding-bottom: 10px;
}

.form__thank-you-heading {
    background: #3f7bad;
    color: white;
    margin: 0;
    padding: 10px;
    font-size: 1.3rem;
}

.form__thank-you-text {
    margin: 10px;
}

.form__thank-you-link {
    box-shadow: 0 0 10px lightgrey;
    border: 1px solid lightgrey;
    border-radius: 3px;
    padding: 5px;
    color: darkSlateGrey;
    text-decoration: none;
    font-size: 0.8rem;
    margin: 10px;

}

.error {
    color: red;
}

.form__date.dob_year.error {
    color: darkSlateGrey;
}

