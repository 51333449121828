/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .header__element { ... }
// .header__element--modifier { ... }
// .header__sub-element { ... }

.header {
    width: 100%;
    background: #F0F6F9;
    border-top: 3px solid #0087C2;
    height: 8rem;
}

.banner {
    text-align: -webkit-center;
    vertical-align: top;
    position: relative;
}

.main__logo {
    height: 106px;
    margin-left: 7px;
    margin-top: 13px;
    float: left;
}

.ausleave__motto {
    font-size: 9px;
    top: 20px;
    right: 5px;
    font-weight: 100;
    color: #0094CC;
    text-align: right;
    position: absolute;
}

b {
    font-weight: 600;
}