/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass

// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.

// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .header__element { ... }
// .header__element--modifier { ... }
// .header__sub-element { ... }

.banner {
    height: 144px;
    text-align: -webkit-center;
    vertical-align: top;
    max-width: 900px;
    margin: 0 auto;
}

.main__logo {
    height: 106px;
    margin-left: 10px;
    margin-top: 10px;
}

.ausleave__motto {
    font-size: 11px;
    top: 47px;
    right: 60px;
}